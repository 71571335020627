import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const HealthChart = ({ caller, clients }) => {

    const getClientStats = (client) => {
        let atRisk = 0;
        let healthy = 0;

        client.businessUnits.forEach(bu => {
            bu.teams.forEach(team => {
                if (team.performanceIndex.overallHealth === 'Healthy') {
                    healthy++;
                } else if (['At Risk', 'Critical'].includes(team.performanceIndex.overallHealth)) {
                    atRisk++;
                }
            });
        });

        return { atRisk, healthy };
    };

    const data = {
        labels: caller === 'client' ? clients.map(client => client.clientName) : clients.flatMap(client => client.businessUnits.map(bu => bu.buName)),
        datasets: [
            {
                label: 'Healthy Teams',
                data: caller === 'client' ? clients.map(client => {
                    const stats = getClientStats(client);
                    return stats.healthy > 0 ? stats.healthy : null;
                }) : clients.flatMap(client => client.businessUnits.map(bu => bu.teams.length - bu.teamsAtRisk)),
                backgroundColor: 'rgba(34, 197, 94, 0.8)',
                borderColor: 'rgba(34, 197, 94, 1)',
                borderWidth: 1,
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
            {
                label: 'At Risk Teams',
                data: caller === 'client' ? clients.map(client => {
                    const stats = getClientStats(client);
                    return stats.atRisk > 0 ? stats.atRisk : null;
                }) : clients.flatMap(client => client.businessUnits.map(bu => bu.teamsAtRisk)),
                backgroundColor: 'rgba(239, 68, 68, 0.8)',
                borderColor: 'rgba(239, 68, 68, 1)',
                borderWidth: 1,
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Team Health by Client',
                font: {
                    size: 16,
                },
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y;
                        return `${label}: ${value} teams`;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    stepSize: 5,
                },
            },
        },
    };

    return (
        <div className="flex-1 bg-white p-6 rounded-lg shadow-sm border border-gray-200 mb-8">
            <div style={{ height: '400px' }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
    Chip,
    Button,
    CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { camelToTitle } from "../common/utils"
// Example Icons for headings
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIcon from '@mui/icons-material/Assignment';

const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size));
    }
    return chunks;
};

const getChipColor = (value) => {
    if (value === 'Healthy') return { backgroundColor: '#00800030', color: '#008000' }; // Green
    if (value === 'At Risk') return { backgroundColor: 'rgb(254 252 232)', color: 'rgb(161 98 7)' }; // Amber
    if (value === 'Critical') return { backgroundColor: '#FF000030', color: '#FF0000' }; // Red
    return { backgroundColor: '#80000010', color: '#800000' }; // Default
};

const TeamDetailPage = () => {
    const { customerKey, buKey, teamKey } = useParams();
    const navigate = useNavigate();
    const [teamData, setTeamData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeamDetails = async () => {
            try {
                setIsLoading(true);
                const response = await fetch("/data.json");
                const json = await response.json();

                const teamInfo = ((json.data.find(client => client.clientName === customerKey).businessUnits).find(bu => bu.buName === buKey).teams).find(team => team.teamName === teamKey)
                if (!teamInfo) throw new Error("Team not found");

                setTeamData({
                    teamName: teamKey,
                    teamMembers: teamInfo.teamMembers,
                    performanceIndex: teamInfo.performanceIndex,
                    previousOutcomes: teamInfo.prevOutcomes,
                    nextPointOfValue: teamInfo.nextPov,
                    keyNotesAndRisk: teamInfo.keyNotesAndRisk,
                    customerBusinessImpact: teamInfo.customerBusinessImpact,
                    growthOfTheTeam: teamInfo.growthOfTheTeam,
                    teamStartDate: teamInfo.teamStartDate,
                    lastUpdated: teamInfo.lastUpdated
                });
            } catch (err) {
                setError(err.message);
                console.error("Error fetching team details:", err);
            } finally {
                setIsLoading(false);
            }
        };

        if (customerKey && buKey && teamKey) {
            fetchTeamDetails();
        }
    }, [customerKey, buKey, teamKey]);

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Typography variant="h6" color="error">
                    Error Loading Team Details
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {error}
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate(-1)}
                    sx={{ mt: 2 }}
                >
                    Go Back
                </Button>
            </Box>
        );
    }

    // teamData is loaded
    // Let's conditionally build an array of "cards" that actually have data
    const cards = [];

    // 1. Team Members
    if (Array.isArray(teamData.teamMembers) && teamData.teamMembers.length > 0) {
        cards.push({
            title: "Team Members",
            icon: <PersonIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                teamData.teamMembers.map((member, idx) => (
                    <Typography key={idx} variant="body1" sx={{ mb: 0.5, ml: 4 }}>
                        {member}{idx === 0 ? " (C) " : ""}
                    </Typography>
                ))
            )
        });
    }

    // 2. Performance Index
    if (teamData.performanceIndex && Object.keys(teamData.performanceIndex).length > 0) {
        cards.push({
            title: "Performance Index",
            icon: <AssignmentTurnedInIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <Grid container spacing={1} sx={{ ml: 0 }}>
                    {Object.entries(teamData.performanceIndex).filter(([key]) => key!== 'overallHealth').map(([key, value]) => (
                        <Grid item xs={12} sm={6} key={key}>
                            <Typography component={'span'}
                                variant="body2"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#555'
                                }}
                            >
                                {camelToTitle(key)} -{' '}
                                <Chip
                                    label={value ?? 'N/A'}
                                    sx={{
                                        fontWeight: 'bold',
                                        ...getChipColor(value)
                                    }}
                                />
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )
        });
    }

    // 3. Previous Outcomes
    if (Array.isArray(teamData.previousOutcomes) && teamData.previousOutcomes.length > 0) {
        cards.push({
            title: "Previous Outcomes",
            icon: <ListAltIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <ol className="list-decimal ml-4 pl-10" style={{ marginLeft: '1.5rem' }}>
                    {teamData.previousOutcomes.map((outcome, idx) => (
                        <li key={idx} style={{ marginBottom: '0.5rem' }}>
                            <Typography variant="body2" sx={{ color: '#333' }}>
                                {outcome}
                            </Typography>
                        </li>
                    ))}
                </ol>
            )
        });
    }

    // 4. Next Point of Value
    if (Array.isArray(teamData.nextPointOfValue) && teamData.nextPointOfValue.length > 0) {
        cards.push({
            title: "Next Point Of Value",
            icon: <AssignmentIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <ol className="list-decimal ml-4 pl-10" style={{ marginLeft: '1.5rem' }}>
                    {teamData.nextPointOfValue.map((pov, idx) => (
                        <li key={idx} style={{ marginBottom: '0.5rem' }}>
                            <Typography variant="body2" sx={{ color: '#333' }}>
                                {pov}
                            </Typography>
                        </li>
                    ))}
                </ol>
            )
        });
    }

    // 5. Key Notes and Risk
    if (teamData.keyNotesAndRisk && teamData.keyNotesAndRisk.trim()) {
        cards.push({
            title: "Key Notes and Risk",
            icon: <PersonIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <Typography variant="body2" sx={{ ml: 4 }}>
                    {teamData.keyNotesAndRisk}
                </Typography>
            )
        });
    }

    // 6. Customer Business Impact
    if (teamData.customerBusinessImpact && teamData.customerBusinessImpact.trim()) {
        cards.push({
            title: "Customer Business Impact",
            icon: <AssignmentTurnedInIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <Typography variant="body2" sx={{ ml: 4 }}>
                    {teamData.customerBusinessImpact}
                </Typography>
            )
        });
    }

    // 7. Growth of the Team
    if (teamData.growthOfTheTeam && teamData.growthOfTheTeam.trim()) {
        cards.push({
            title: "Growth of the Team",
            icon: <PersonIcon sx={{ color: '#800000', mr: 1 }} />,
            render: () => (
                <Typography variant="body2" sx={{ ml: 4 }}>
                    {teamData.growthOfTheTeam}
                </Typography>
            )
        });
    }

    // 8. Team Start Date
    // if (teamData.teamStartDate && teamData.teamStartDate.trim()) {
    //     cards.push({
    //         title: "Team Start Date",
    //         icon: <AssignmentIcon sx={{ color: '#800000', mr: 1 }} />,
    //         render: () => (
    //             <Typography variant="body2" sx={{ ml: 4 }}>
    //                 {new Date(teamData.teamStartDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
    //             </Typography>
    //         )
    //     });
    // }

    // 9. Last Updated
    // if (teamData.lastUpdated && teamData.lastUpdated.trim()) {
    //     cards.push({
    //         title: "Last Updated",
    //         icon: <AssignmentTurnedInIcon sx={{ color: '#800000', mr: 1 }} />,
    //         render: () => (
    //             <Typography variant="body2" sx={{ ml: 4 }}>
    //                 {new Date(teamData.lastUpdated).toLocaleString('en-US', {
    //                     year: 'numeric',
    //                     month: 'long',
    //                     day: 'numeric',
    //                     hour: '2-digit',
    //                     minute: '2-digit',
    //                     second: '2-digit'
    //                 })}                </Typography>
    //         )
    //     });
    // }

    // Now we chunk the cards array into groups of 2 for a responsive 2-column layout
    const chunkedCards = chunkArray(cards, 2);

    return (
        <Box
            sx={{
                minHeight: '100vh',
                backgroundColor: 'rgb(240, 250, 243)', // Page background
                py: { xs: 2, sm: 4 }
            }}
        >
            <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3 } }}>
                {/* Back Button */}
                <Button
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                    sx={{
                        mb: { xs: 2, sm: 3 },
                        borderColor: '#800000',
                        color: '#800000',
                        '&:hover': {
                            borderColor: '#800000',
                            backgroundColor: '#80000010',
                        }
                    }}
                >
                    Back
                </Button>

                {/* Team Header */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "centre", gap:2, mb: { xs: 2, sm: 4 } }}>
                    <span className="mr-auto text-sm text-gray-500 font-semibold">Start Date: {new Date(teamData.teamStartDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            color: "#800000", // Maroon
                            fontWeight: 'bold',
                            textTransform: 'capitalize'
                        }}
                    >
                        {teamData.teamName}
                    </Typography>
                    <span className="ml-auto text-sm text-gray-500 font-semibold">Last Updated: {new Date(teamData.lastUpdated).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                </Box>

                {/* Dynamically render the cards in rows of up to 2 columns */}
                {chunkedCards.map((rowCards, rowIndex) => (
                    <Grid container spacing={3} sx={{ mb: 3 }} key={rowIndex}>
                        {rowCards.map((card, cardIndex) => (
                            <Grid item xs={12} md={6} key={cardIndex}>
                                <Card
                                    sx={{
                                        backgroundColor: '#fafafa',
                                        borderRadius: 2,
                                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            {card.icon}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: '#800000',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {card.title}
                                            </Typography>
                                        </Box>
                                        {card.render()}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ))}

                {/* If cards array is empty, you could show a fallback message */}
                {cards.length === 0 && (
                    <Typography variant="body1" sx={{ textAlign: 'center', mt: 4, color: '#666' }}>
                        No details available for this team.
                    </Typography>
                )}
            </Container>
        </Box>
    );
};

export default TeamDetailPage;

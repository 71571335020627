import React, { useState, useEffect } from "react";
import { Insights } from "../components/Insights";

const Dashboard = () => {
    const [clientInfo, setClientInfo] = useState([]);
    const [metrics, setMetrics] = useState({});
    const [lastModified, setLastModified] = useState("");

    useEffect(() => {
        fetch("/data.json")
            .then((response) => response.json())
            .then((jsonData) => {
                setClientInfo(jsonData.data);
                setMetrics(jsonData.metrics);
                setLastModified(jsonData.lastModified);
            });
    }, []);

    return (
        <div>
            <Insights caller="client"
                clientInfo={clientInfo} metrics={metrics}
                lastModified={lastModified}
            />
        </div>
    );
}

export default Dashboard;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Insights } from "../components/Insights";

const TeamListing = () => {
    const { customerKey } = useParams();
    const [clientInfo, setClientInfo] = useState([]);
    const [lastModified, setLastModified] = useState("");

    useEffect(() => {
        fetch("/data.json")
            .then((res) => res.json())
            .then((json) => {
                setClientInfo( [(json.data.find(client => client.clientName === customerKey))] );
                setLastModified(json.lastModified);
            });
    }, [customerKey]);

    return (
        <div>
            <Insights caller="team"
                clientInfo={clientInfo}
                lastModified={lastModified}
            />
        </div>
    );
};

export default TeamListing;

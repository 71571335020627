
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TeamPage from "./Team/TeamSelection";
import MovingGraph from "./MovingGraph";
import Overview from "./TeamOverview/Overview";
import ClientDetailPage from "./pages/ClientDetails";
import NotFoundPage from "./pages/NotFoundPage";
import TeamDetailPage from "./pages/TeamDetails";
import TeamListing from "./pages/TeamListingNew";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./LoginPage";
import UnauthorizedPage from "./UnauthorizedPage";
import ProtectedRoute from "./ProtectedRoute";
import { Header } from "./components/Header";
import ScrollToTop from './common/ScrollToTop';

import { createTheme, ThemeProvider } from "@mui/material"

const theme = createTheme({
  palette: {
    primary: {
      main: '#800000', // Maroon
    },
    secondary: {
      main: '#ffffff', // White
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});



function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <div style={{ marginBottom: "3em" }}></div>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/error" element={<UnauthorizedPage />} />
            {/* <Route path="/mhe" element={
              <ProtectedRoute>
                <TeamPage />
              </ProtectedRoute>
            } /> */}
            {/* <Route path="/graph" element={
              <ProtectedRoute>
                <MovingGraph />
              </ProtectedRoute>
            } /> */}
            {/* <Route path="/mhe/:team" element={
              <ProtectedRoute>
                <Overview />
              </ProtectedRoute>
            } /> */}
            <Route path="/customers" element={
              <ProtectedRoute>
                <ClientDetailPage />
              </ProtectedRoute>
            } />
            <Route path="/customers/:customerKey" element={
              <ProtectedRoute>
                <TeamListing />
              </ProtectedRoute>
            } />
            <Route path="/customers/:customerKey/:buKey/:teamKey" element={
              <ProtectedRoute>
                <TeamDetailPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />

            <Route path="*" element={
              <ProtectedRoute>
                <NotFoundPage />
              </ProtectedRoute>
            } />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
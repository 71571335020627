import React, { useState } from "react";
import { MetricsCard } from '../components/MetricsCard';
import { HealthChart } from '../components/HealthChart';
import { TeamCard } from '../components/TeamCard';
import { Building, Users, AlertTriangle, ChevronDown, ChevronRight, HelpCircle, X } from 'lucide-react';
import { useNavigate } from "react-router-dom";

export const Insights = ({ caller, clientInfo, metrics, lastModified }) => {

    const [expandedClients, setExpandedClients] = useState(new Set());
    const [expandedBUs, setExpandedBUs] = useState(new Set());
    const [activeTab, setActiveTab] = useState('risk');
    const [showHelpModal, setShowHelpModal] = useState(false);
    const navigate = useNavigate();

    const handleTeamClick = (clientName, buName, teamName) => {
        navigate(
            `/customers/${clientName}/${buName}/${encodeURIComponent(teamName)}`
        );
    };

    const allTeams = clientInfo.flatMap((client) =>
        client.businessUnits.flatMap((bu) =>
            bu.teams.map((team) => ({
                ...team,
                clientName: client.clientName,
                buName: bu.buName,
            }))
        )
    );

    function calcNewTeams(allTeams) {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return allTeams.filter((team) => new Date(team.teamStartDate) >= threeMonthsAgo)
            .sort(
                (a, b) => new Date(b.teamStartDate) - new Date(a.teamStartDate)
            );
    }

    const getFilteredTeams = () => {
        switch (activeTab) {
            case "risk":
                return allTeams
                    .filter(
                        (team) =>
                            team.performanceIndex.overallHealth === "At Risk" ||
                            team.performanceIndex.overallHealth === "Critical"
                    )
                    .sort((a, b) =>
                        b.performanceIndex.overallHealth.localeCompare(
                            a.performanceIndex.overallHealth
                        )
                    );
            case "new":
                return calcNewTeams(allTeams)
            default:
                return [];
        }
    };

    const toggleClient = (clientName) => {
        const newExpanded = new Set(expandedClients);
        if (newExpanded.has(clientName)) {
            newExpanded.delete(clientName);
        } else {
            newExpanded.add(clientName);
        }
        setExpandedClients(newExpanded);
    };

    const toggleBU = (buName) => {
        const newExpanded = new Set(expandedBUs);
        if (newExpanded.has(buName)) {
            newExpanded.delete(buName);
        } else {
            newExpanded.add(buName);
        }
        setExpandedBUs(newExpanded);
    };

    const renderHierarchicalView = () => (
        <div className="space-y-4">
            {clientInfo.map((client) => (
                <div
                    key={client.clientName}
                    className="bg-white rounded-lg shadow-sm border border-gray-200"
                >
                    <button
                        className="w-full px-4 py-3 flex items-center justify-between text-left hover:bg-gray-50"
                        onClick={() => toggleClient(client.clientName)}
                    >
                        <div className="flex items-center">
                            {expandedClients.has(client.clientName) ? (
                                <ChevronDown className="w-5 h-5 text-gray-400 mr-2" />
                            ) : (
                                <ChevronRight className="w-5 h-5 text-gray-400 mr-2" />
                            )}
                            <img
                                src={`/images/${client.clientName}.png`}
                                alt={`${client.clientName} logo`}
                                className="max-w-[8rem] h-12 rounded-lg object-contain mr-3"
                            />
                            {/* <span className="font-medium text-gray-900">{client.clientName}</span> */}
                        </div>
                        <span className="text-sm text-gray-500">
                            {client.businessUnits.reduce(
                                (acc, bu) => acc + bu.teams.length,
                                0
                            )}{" "}
                            Teams
                        </span>
                    </button>

                    {expandedClients.has(client.clientName) && (
                        <div className="pl-8 pr-4 pb-4">
                            {client.businessUnits.map((bu) => (
                                <div key={bu.buName} className="mt-2">
                                    <button
                                        className="w-full px-4 py-2 flex items-center justify-between text-left hover:bg-gray-50 rounded"
                                        onClick={() => toggleBU(bu.buName)}
                                    >
                                        <div className="flex items-center">
                                            {expandedBUs.has(bu.buName) ? (
                                                <ChevronDown className="w-4 h-4 text-gray-400 mr-2" />
                                            ) : (
                                                <ChevronRight className="w-4 h-4 text-gray-400 mr-2" />
                                            )}
                                            <span className="text-sm font-medium text-gray-700">
                                                {bu.buName}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-4">
                                            {bu.teamsAtRisk > 0 && (
                                                <span className="text-sm text-red-600">
                                                    At Risk: {bu.teamsAtRisk}
                                                </span>
                                            )}
                                            <span className="text-sm text-gray-500">
                                                Total: {bu.teams.length}
                                            </span>
                                        </div>
                                    </button>

                                    {expandedBUs.has(bu.buName) && (
                                        <div className="pl-8 pr-4 mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                            {bu.teams.map((team) => (
                                                <TeamCard
                                                    key={team.teamName}
                                                    team={{
                                                        ...team,
                                                        clientName: client.clientName,
                                                        buName: bu.buName,
                                                    }}
                                                    onClick={handleTeamClick}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );

    const calcTeamMetrics = () => {
        const metrics = {}
        metrics.totalClients = clientInfo.map(client => client.businessUnits.length);
        metrics.totalTeams = clientInfo.map(client => client.businessUnits.reduce((acc, bu) => acc + bu.teams.length, 0));
        metrics.teamsAtRisk = clientInfo.map(client => client.businessUnits.reduce((acc, bu) => acc + bu.teamsAtRisk, 0));
        metrics.newTeams = calcNewTeams(allTeams).length;
        return metrics;
    }

    const HelpModal = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full mx-4 max-h-[85vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">How to Update Team Information</h2>
                    <button
                        onClick={() => setShowHelpModal(false)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <div className="prose p-4">
                    <h3 className="text-lg font-semibold mb-2">To update Engagement Health details, follow these steps:</h3>
                    <ol className="list-decimal mb-4 pl-5 space-y-2">
                        <li>Go to the <a href="https://triconindia-my.sharepoint.com/:x:/r/personal/adi_chikara_triconinfotech_com/Documents/Engagement%20Health%20Index.xlsx?d=w7cdd34c29fbf4dd7b600c9bbc8d49568&csf=1&web=1&e=WWHyMF" target="_blank" class="text-blue-500 hover:underline">Engagement Sheet</a> and navigate to <strong>Health Index</strong> sheet.</li>
                        <li>Create a new row for new team.</li>
                        <li>Update the following fields:
                            <ul className="list-disc pl-5 mt-2">
                                <li>Customer</li>
                                <li>Team</li>
                                <li>Lead</li>
                                <li>Start Date</li>
                                <li>Last Updated Date</li>
                                <li>Delivery, Team, and Relationship Health</li>
                                <li>Growth of the Team</li>
                                <li>Key Notes</li>
                                <li>Customer Business Impact</li>
                            </ul>
                        </li>
                    </ol>
                    <h3 className="text-lg font-semibold mb-2">To update Team related details, follow these steps:</h3>
                    <ol className="list-decimal pl-5 space-y-2">
                        <li>Navigate to the <a href="https://triconindia-my.sharepoint.com/:x:/g/personal/mohak_khattwani_triconinfotech_com/EcgXns3bD2NDgVgPqcctAPcBRh0NfWeldh9ZzbEL122tkw" target="_blank" class="text-blue-500 hover:underline">Mission Control</a> Sheet.</li>
                        <li>Update the following fields:
                            <ul className="list-disc pl-5 mt-2">
                                <li>Customer</li>
                                <li>Business Unit</li>
                                <li>Team</li>
                                <li>Team Members</li>
                                <li>Previous Outcomes</li>
                                <li>Next Point of Value</li>
                                <li>Cycle Time</li>
                                <li>Product Lifecycle</li>
                                <li>Business Practices</li>
                            </ul>
                        </li>
                    </ol>


                    <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                        <p className="text-blue-800 text-sm">
                            <strong>Important Notes:</strong> <br />
                            Customer and Team names must match across the sheets.<br />
                            The data updated in these sheets will take time to reflect in Mission Control. Updates are processed every <strong>Wednesday and Thursday.</strong><br /><br />
                            For any further queries, please reach out to the <strong>Engagement Leadership Group</strong> on Microsoft Teams.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            setShowHelpModal(false)
        }
    });

    return (
        <div className="min-h-screen pt-8">
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex items-center justify-between mb-8">
                    <h1 className="text-3xl font-bold text-[#800000]">{caller === 'client' ? 'Team Dashboard' : `${clientInfo.map(client => client.clientName)} Dashboard`}</h1>
                    <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500 font-semibold">
                            Last updated: {lastModified}
                        </span>
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row">
                    <MetricsCard
                        caller={caller}
                        metrics={caller === 'client' ? metrics : calcTeamMetrics()}
                        switchActiveTab={(tab) => setActiveTab(tab)}
                    />

                    <HealthChart caller={caller} clients={clientInfo} />
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                    <div className="border-b border-gray-200">
                        <nav className="flex -mb-px">
                            <button
                                className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${activeTab === "risk"
                                    ? "border-b-2 border-red-500 text-red-600"
                                    : "text-gray-500 hover:text-gray-700"
                                    }`}
                                onClick={() => setActiveTab("risk")}
                            >
                                <AlertTriangle className="w-5 h-5" />
                                <span>At Risk Teams</span>
                            </button>
                            <button
                                className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${activeTab === "new"
                                    ? "border-b-2 border-blue-500 text-blue-600"
                                    : "text-gray-500 hover:text-gray-700"
                                    }`}
                                onClick={() => setActiveTab("new")}
                            >
                                <Users className="w-5 h-5" />
                                <span>New Teams</span>
                            </button>
                            <button
                                className={`px-6 py-4 text-sm font-medium flex items-center space-x-2 ${activeTab === "all"
                                    ? "border-b-2 border-gray-500 text-gray-600"
                                    : "text-gray-500 hover:text-gray-700"
                                    }`}
                                onClick={() => setActiveTab("all")}
                            >
                                <Building className="w-5 h-5" />
                                <span>All Teams</span>
                            </button>
                        </nav>
                    </div>

                    <div className="p-6">
                        {activeTab === "all" ? (
                            renderHierarchicalView()
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {getFilteredTeams().map((team) => (
                                    <TeamCard
                                        team={team}
                                        key={team.teamName}
                                        onClick={handleTeamClick}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <button
                    onClick={() => setShowHelpModal(true)}
                    className="fixed bottom-5 right-5 p-3 bg-[#800000] text-white rounded-full hover:text-[#800000] hover:bg-white border border-[#800000]"
                >
                    <HelpCircle className="w-6 h-6" />
                </button>
            </div>
            {showHelpModal && <HelpModal />}
        </div>
    );
};

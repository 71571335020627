import React from 'react';

export const MetricsCard = ({ metrics, switchActiveTab, caller }) => {
  return (
    <div className="grid grid-rows-auto gap-4 mr-8 mb-8">
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <h3 className="text-sm font-medium text-gray-500">{caller === 'client' ? 'Total Clients' : 'Total Business Units'}</h3>
        <p className="text-2xl font-bold text-gray-900">{metrics.totalClients}</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 cursor-pointer" onClick={() => switchActiveTab('all')}>
        <h3 className="text-sm font-medium text-gray-500">Total Teams</h3>
        <p className="text-2xl font-bold text-gray-900">{metrics.totalTeams}</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 cursor-pointer" onClick={() => switchActiveTab('new')}>
        <h3 className="text-sm font-medium text-gray-500">New Teams (Started in last 3 months)</h3>
        <p className="text-2xl font-bold text-blue-600">{metrics.newTeams}</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 cursor-pointer" onClick={() => switchActiveTab('risk')}>
        <h3 className="text-sm font-medium text-gray-500">Teams at Risk</h3>
        <p className="text-2xl font-bold text-red-600">{metrics.teamsAtRisk}</p>
      </div>
    </div>
  );
};
import {
    AppBar,
    Toolbar,
    Button,
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    IconButton,
    Tooltip
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

export function Header() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const logOut = () => {
        localStorage.removeItem("isLoggedIn");
        navigate("/");
    };

    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

    return <AppBar
        // position="fixed"
        elevation={0}
        sx={{
            backgroundColor: 'white',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
            height: { xs: 64, sm: 72 },
            zIndex: 40
        }}
    >
        <Toolbar sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: { xs: 1, sm: 2 },
            px: { xs: 2, sm: 4 },
            minHeight: { xs: 64, sm: 72 },
        }}>
            {/* Logo with subtle hover effect and proper sizing */}
            <Box
                component="img"
                src={'/images/triconLogo.png'}
                sx={{
                    width: { xs: 40, sm: 54 },
                    height: { xs: 40, sm: 54 },
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: 'scale(1.05)',
                    }
                }}
                onClick={() => navigate('/')}
            />
            
            {/* App name with proper styling */}
            <Typography
                variant="h6"
                sx={{ 
                    color: '#800000',
                    fontWeight: 'bold',
                    fontSize: { xs: '1.1rem', sm: '1.25rem' },
                    cursor: 'pointer',
                    letterSpacing: '0.5px',
                    '&:hover': {
                        opacity: 0.9,
                    }
                }}
                onClick={() => navigate('/')}
            >
                Mission Control
            </Typography>
            
            {/* Right-side content with responsive spacing */}
            <Box sx={{ 
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
                gap: { xs: 1, sm: 2 }
            }}>
                {isLoggedIn && (
                    <>
                        {/* User info indicator (optional) */}
                        <Box sx={{ 
                            display: { xs: 'none', sm: 'flex' },
                            alignItems: 'center',
                            pr: 2,
                            borderRight: '1px solid rgba(0,0,0,0.1)',
                        }}>
                            <Tooltip title="User Profile">
                                <IconButton
                                    size="small"
                                    sx={{ 
                                        color: 'rgba(128, 0, 0, 0.7)',
                                        mr: 1
                                    }}
                                >
                                </IconButton>
                            </Tooltip>
                        </Box>
                        
                        {/* Logout button with responsive design */}
                        {isMobile ? (
                            <Tooltip title="Logout">
                                <IconButton
                                    color="primary"
                                    onClick={() => logOut()}
                                    sx={{
                                        color: '#800000',
                                        '&:hover': {
                                            backgroundColor: 'rgba(128, 0, 0, 0.08)',
                                        }
                                    }}
                                >
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Button
                                variant="outlined"
                                startIcon={<LogoutIcon />}
                                sx={{
                                    borderRadius: 8,
                                    borderWidth: 1.5,
                                    textTransform: "none",
                                    fontSize: "0.9rem",
                                    borderColor: '#800000',
                                    color: '#800000',
                                    px: 2,
                                    py: 0.75,
                                    '&:hover': {
                                        backgroundColor: 'rgba(128, 0, 0, 0.04)',
                                        borderColor: '#800000',
                                        borderWidth: 1.5,
                                    }
                                }}
                                onClick={() => logOut()}
                            >
                                Logout
                            </Button>
                        )}
                    </>
                )}
            </Box>
        </Toolbar>
    </AppBar>
}